import React from "react";
import ParticlesBg from "particles-bg";
import { Fade } from "react-awesome-reveal";
import styled from "styled-components";

const MyHeader = styled.header`
    height: 100vh;
`;

const Header = () => {
    const project = "";
    const github = "";
    const name = "Versatility AI";
    const description = "buidling a versatile PC for everyone";

    return (
        <MyHeader>
            <ParticlesBg type="lines" bg={true} />
            <div className="row banner">
                <div className="banner-text">
                    <Fade bottom>
                        <h1 className="responsive-headline">{name}</h1>
                    </Fade>
                    <Fade bottom duration={1200}>
                        <h3>{description}.</h3>
                    </Fade>
                </div>
            </div>
        </MyHeader>
    );
};

export default Header;
